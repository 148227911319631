<template>
	<el-drawer title="会员等级" :visible.sync="isDrawer" :with-header="false" size="86%" :before-close="handleClose"
		:modal-append-to-body="false" :wrapperClosable="false" :close-on-press-escape="false">
		<div class="h-full flex flex-dc">
			<div class="drawer_title pall-30 flex flex-jb flex-n">
				<div class="fs-18 fw-bold">会员等级</div>
				<i class="el-icon-close fs-20 pointer" @click="handleClose"></i>
			</div>
			<div class="flex-1 overflow-scroll-y">
				<t-simple-form :ref-obj.sync="formOpts.ref" :formOpts="formOpts" :widthSize="4" @handleEvent="handleEvent"
					class="drawer_form">
					<!-- 平台自定义插槽 -->
					<!-- 					<template #props>
						<div class="pos-s t-0 bgc-fff zi-10">
							<div class="pb-20 pl-40 flex flex-ac flex-jb">
								<div class="fs-14 fw-bold flex-1">
									<span class="h-10 w-5 bgc-38393b mr-10 dis-in-block"></span>
									<span>道具列表</span>
								</div>
								<div class="flex flex-ac">
									<el-button type="primary" @click="addDomain">新增</el-button>
								</div>
							</div>
						</div>
						<div v-for="(domains,index) in formOpts.formData.props" class="flex flex-ac mb-20"
							:key="index">
							<el-form-item label-width="135px" :label="'商品名称'" label-position="right"
								:prop="'props.' + index + '.propName'"
								:rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.propName"></el-input>
							</el-form-item>
							<el-form-item label-width="135px" label="道具类型" label-position="right"
								:prop="'props.' + index + '.propType'"
								:rules="{required: true, message: '请选择道具类型', trigger: 'change'}" class="flex flex-ac"
								v-if="!testFlag">
								<el-select v-model="domains.propType" clearable placeholder="请选择">
									<el-option v-for="item in formOpts.listTypeInfo.propTypes" :key="item.value"
										:label="item.key" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-form-item label-width="90px" label="道具图片" label-position="right"
								:prop="'props.' + index + '.propImg'"
								:rules="{required: true, message: '图片不能为空', trigger: 'blur'}" class="flex flex-ac">
								<upload-file upStyle="width: 50px; height: 50px; border-radius: 5px; overflow: hidden;"
									iconName="el-icon-upload" iconSize="16" v-model="domains.propImg"></upload-file>
							</el-form-item>
							<el-form-item label-width="135px" :label="'道具数量'" label-position="right"
								:prop="'props.' + index + '.num'"
								:rules="{required: true, message: '请输入道具数量', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.num"></el-input>
							</el-form-item>
							<el-form-item label-width="90px" v-if="domains.propType == 4" :label="'面额'"
								label-position="right" :prop="'props.' + index + '.money'"
								:rules="{required: true, message: '面额不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.money"></el-input>
							</el-form-item>
							<el-form-item label-width="135px" v-if="domains.propType == 4" :label="'道具门槛'"
								label-position="right" :prop="'props.' + index + '.doorSill'"
								:rules="{required: true, message: '道具名称不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.doorSill"></el-input>
							</el-form-item>
							<el-form-item label-width="120px" :label="'有效期'" label-position="right"
								:prop="'props.' + index + '.validDay'"
								:rules="{required: true, message: '有效期不能为空', trigger: 'blur'}" class="flex flex-ac">
								<el-input v-model="domains.validDay"></el-input>
							</el-form-item>
							<el-form-item label-width="135px" v-if="domains.propType == 4" label="优惠券类型"
								label-position="right" :prop="'props.' + index + '.couponsType'"
								:rules="{required: true, message: '请选择优惠券类型', trigger: 'change'}" class="flex flex-ac">
								<el-select v-model="domains.couponsType" clearable placeholder="请选择">
									<el-option v-for="item in formOpts.listTypeInfo.couponsTypes" :key="item.value"
										:label="item.key" :value="item.value">
									</el-option>
								</el-select>
							</el-form-item>
							<el-button type="primary" plain @click.prevent="removeDomain(index)" class="ml-20"
								v-if="formOpts.formData.props.length > 1">删除 </el-button>
						</div>
					</template> -->
					<!-- 上传图片 -->
					<!-- 					<template #uploadFile="data">
						<upload-file upStyle="width: 80px; height: 80px; border-radius: 5px; overflow: hidden;"
							iconName="el-icon-upload" iconSize="26" v-model="formOpts.formData[data.value]">
						</upload-file>
					</template> -->
				</t-simple-form>
			</div>
			<div class="drawer_button pall-30 flex-n flex flex-ac flex-je">
				<el-button type="primary" @click="submitForm" :loading="butLoading">{{goodId?'保存':'提交'}}</el-button>
				<el-button type="primary" plain @click="handleClose">取消</el-button>
			</div>
		</div>
	</el-drawer>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: 'drawsDetails',
		components: {
			"TSimpleForm": (resolve) => require(["@/components/public/TSimpleForm"], resolve),
			"UploadFile": (resolve) => require(["@/components/UploadFile"], resolve),
		},
		data() {
			return {
				dialogVisible: false, //确认展示套数，提示弹框
				goodId: '', //详情id
				isDrawer: false,
				butLoading: false,
				fieldTemplateList: [{
					label: '累计消费',
					value: 'consume',
					comp: 'el-input',
					type: 'number'
				}, {
					label: '月消费',
					value: 'monthConsume',
					comp: 'el-input',
					type: 'number'
				}, {
					label: '等级',
					value: 'level',
					comp: 'el-input',
					type: 'number'
				}, {
					label: '道具名称',
					value: 'propName',
					comp: 'el-input',
				}, {
					label: '道具数量',
					value: 'propNum',
					comp: 'el-input',
				}, {
					label: '道具类型',
					value: 'propType',
					type: 'select-arr',
					comp: 'el-select',
					list: 'propTypes',
					arrLabel: 'key',
					arrKey: 'value',
					event: 'showCoupons'
				}, {
					label: '有效期',
					value: 'validDay',
					comp: 'el-input',
					type: 'number'
				}],
				formOpts: {
					labelPosition: 'top',
					ref: null,
					formData: {
						consume: '', //累计消费
						monthConsume: '', //月消费
						level: '', //等级
						propName: '', //道具名称
						propType: '', //道具类型
						money: '', //道具面额
						doorSill: '', //使用门槛
					},
					fieldList: this.fieldTemplateList,
					rules: {
						// seriesCode: [
						//   { required: true, message: '请输入系列编号', trigger: 'blur' },
						//   { min: 3, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
						// ],
						consume: [{
							required: true,
							message: '累计消费不能为空',
							trigger: 'blur'
						}],
						monthConsume: [{
							required: true,
							message: '月消费',
							trigger: 'blur'
						}],
						level: [{
							required: true,
							message: '等级不能为空',
							trigger: 'blur'
						}],
						propName: [{
							required: true,
							message: '请输入道具名称',
							trigger: 'blur'
						}],
						validDay: [{
							required: true,
							message: '请输入有效期',
							trigger: 'change'
						}],
						money: [{
							required: true,
							message: '请输入面额',
							trigger: 'change'
						}],
						doorSill: [{
							required: true,
							message: '请输入门槛',
							trigger: 'blur'
						}],
						propType: [{
							required: true,
							message: '请选择道具类型',
							trigger: 'change'
						}],
					},
					//按钮
					operatorList: [],
					// 相关列表
					listTypeInfo: {
						shelves: this.$store.state.menu.dictList.shelves,
						seriesTypes: this.$store.state.menu.dictList.series_type,
						couponsTypes: this.$store.state.menu.dictList.coupons_type, //优惠劵类型
						propTypes: this.$store.state.menu.dictList.prop_type, //道具类型
					}
				},
				testFlag: false, //测试
			}
		},
		created() {
			//测试人员
			// let userInfo = JSON.parse(localStorage.getItem('userInfo'))
			// if (userInfo.name == '测试') {
			//   this.testFlag = true
			// }
		},
		mounted() {
			this.formOpts.fieldList = this.fieldTemplateList
		},
		methods: {
			//取消展示套数的修改
			cancel() {
				this.dialogVisible = false
				this.butLoading = false
			},
			// 初始化
			init(id, type) {
				var that = this
				that.isDrawer = true
				console.log(this.formOpts.fieldList, 'this.formOpts.fieldList')
				if (id) {
					that.goodId = id
					that.getDetails(id)
				} else {
					that.goodId = ''
					this.formOpts.fieldList = this.fieldTemplateList
				}
			},
			//关闭弹框
			handleClose() {
				this.isDrawer = false
				this.resetForm()
			},
			//获取详情数据
			getDetails(id) {
				this.$http.get(`/userLevel/findUserLevelByIdV1?id=${id}`).then(({
					data: res
				}) => {
					let goodData = res.data
					if (goodData.props == null || goodData.props == undefined || goodData.props == "") {
						goodData.props = []
					}
					this.formOpts.formData = goodData
					if (goodData.propType == 4) {
						this.handleEvent('showCoupons', 4)
					}
				})
			},
			// 触发事件
			handleEvent(type, val) {
				switch (type) {
					case 'showCoupons':
						if (val == 4) {
							let arr = [{
								label: '优惠券面额',
								value: 'money',
								type: 'number',
								comp: 'el-input',
							}, {
								label: '门槛',
								value: 'doorSill',
								type: 'number',
								comp: 'el-input'
							}, {
								label: '优惠券类型',
								value: 'couponsType',
								type: 'select-arr',
								comp: 'el-select',
								list: 'couponsTypes',
								arrLabel: 'key',
								arrKey: 'value'
							}]
							this.formOpts.fieldList = this.fieldTemplateList.concat(arr)
						} else {
							this.formOpts.fieldList = this.fieldTemplateList
						}
						break
				}
			},
			// 新增商品
			// addDomain() {
			// 	this.formOpts.formData.props.push({
			// 		num: 0, //道具数量
			// 		propName: '', //道具名称
			// 		propType: '', //道具类型
			// 		propImg: '', //道具图片
			// 		validDay: 0, //有效天数
			// 		money: '', //优惠券面额
			// 		doorSill: '', //使用门槛
			// 		couponsType: '', //优惠券类型
			// 	})
			// },
			removeDomain(index) {
				this.formOpts.formData.props.splice(index, 1)
			},
			editOkSubmit() {
				this.dialogVisible = false
				let goodData = JSON.stringify(this.formOpts.formData)
				// goodData.json = JSON.stringify(goodData.detailsList)
				delete goodData.props
				// goodData.detailsList = JSON.stringify(goodData.detailsList)
				// console.log(goodData.detailsList, 'json')
				let apiUrl = ''
				if (this.goodId) {
					//修改
					apiUrl = '/userLevel/editUserLevel'
				} else { //新增
					apiUrl = '/userLevel/saveUserLevel'
				}
				this.$http.post(apiUrl, goodData, {
					headers: {
						'Content-Type': 'application/json'
					}
				}).then(res => {
					this.$message({
						message: goodData.id ? '修改成功' : '新增成功',
						type: 'success'
					})
					this.butLoading = false
					this.$emit('refresh')
					this.handleClose()
				}).catch(err => {
					this.butLoading = false
				})
			},
			// 提交form表单
			submitForm() {
				this.formOpts.ref.validate((valid) => {
					if (valid) {
						this.butLoading = true
						console.log(77, this.formOpts.formData)
						if (!valid) return
						console.log('最终数据', this.formOpts.formData)
						this.editOkSubmit()
					}
				})
			},
			// 重置form表单
			resetForm() {
				Object.assign(this.$data.formOpts.formData, this.$options.data.call(this).formOpts.formData)
				this.$nextTick(() => {
					this.formOpts.ref.clearValidate()
				})
			},
		}
	}
</script>
<style lang="less" scoped>
</style>